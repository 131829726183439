export const gateway = "https://qat-belowcost-bms-api.link-nonprod.wawanesalife.com";
export const assets = "https://qat-assets.link-nonprod.wawanesalife.com";
export const LoginUrlParameter = "https://qat-brokers.auth.ca-central-1.amazoncognito.com/oauth2/authorize?client_id=1t89lq8ud05afvpaes87bcjbgf&response_type=code&scope=email+openid&redirect_uri=https%3A%2F%2Fqat-belowcost-bms.link-nonprod.wawanesalife.com%2Fredirect";
export const BrokerPoolClientParameter = "1t89lq8ud05afvpaes87bcjbgf";
export const BrokerPoolDomainParameter = "qat-brokers";
export const BrokerPoolParameter = "ca-central-1_26CbSAlrU";

export const brokerBranding = {
	"qat-belowcost-shopper.link-nonprod.wawanesalife.com": {
		a: {
			broker: "belowcost",
			accessibilityLink: "https://en.wikipedia.org/wiki/Web_Content_Accessibility_Guidelines",
			themeSwitcher: "show",
			privacyLink: "https://www.priv.gc.ca/en/privacy-topics/privacy-laws-in-canada/the-personal-information-protection-and-electronic-documents-act-pipeda/",
			scope: "belowcost#branding#qat-belowcost-shopper.link-nonprod.wawanesalife.com#a",
			approve_company: "Link Platform",
			email: "bob@belowcost.lol",
			video_link: "null",
			map2: "845 Dakota St Unit 30,Winnipeg,MB,R2M 5M3",
			environment_id: "qat",
			map1: "840 Waverley St,Winnipeg,MB,R3T 5Z7",
			brands: "qat-belowcost-shopper.link-nonprod.wawanesalife.com",
			map4: "1440 Jack Blick Ave #101c,Winnipeg,MB,R3G 0L4",
			map3: "3020 Preston Ave S #100,Saskatoon,SK,S7T 0V2",
			maps: "[object Object]",
			introduction: "null",
			phone: "(204) 555-4321",
			menuPhone: "button",
			environment: "qat",
			website: "https://qat.belowcost.net/",
			ftcLink: "https://www.fsrao.ca/regulation/guidance/fair-treatment-customers-insurance",
			layout: "modern",
			g4: "G-2QM64W4BM4",
			photo3: "show",
			photos: "carousel1.jpg,carousel2.jpg,carousel3.jpg,carousel4.jpg,carousel5.jpg,carousel6.jpg,carousel7.jpg,carousel8.jpg,carousel9.jpg,carousel10.jpg,carousel11.jpg",
			photo4: "show",
			photo1: "show",
			photo2: "show",
			approve_user: "link_belowcost",
			logoPosition: "menu",
			ab: "a",
			logo: "a.png",
			brand_name: "qat-belowcost-shopper.link-nonprod.wawanesalife.com",
		},
		b: {
			broker: "belowcost",
			accessibilityLink: "https://en.wikipedia.org/wiki/Web_Content_Accessibility_Guidelines",
			themeSwitcher: "show",
			privacyLink: "https://www.priv.gc.ca/en/privacy-topics/privacy-laws-in-canada/the-personal-information-protection-and-electronic-documents-act-pipeda/",
			scope: "belowcost#branding#qat-belowcost-shopper.link-nonprod.wawanesalife.com#b",
			approve_company: "Link Platform",
			email: "bob@belowcost.lol",
			video_link: "null",
			map2: "845 Dakota St Unit 30,Winnipeg,MB,R2M 5M3",
			environment_id: "qat",
			map1: "840 Waverley St,Winnipeg,MB,R3T 5Z7",
			brands: "qat-belowcost-shopper.link-nonprod.wawanesalife.com",
			map4: "1440 Jack Blick Ave #101c,Winnipeg,MB,R3G 0L4",
			map3: "3020 Preston Ave S #100,Saskatoon,SK,S7T 0V2",
			maps: "[object Object]",
			introduction: "null",
			phone: "(204) 555-4321",
			menuPhone: "button",
			environment: "qat",
			website: "https://qat.belowcost.net/",
			ftcLink: "https://www.fsrao.ca/regulation/guidance/fair-treatment-customers-insurance",
			layout: "modern",
			g4: "G-2QM64W4BM4",
			photo3: "show",
			photos: "carousel1.jpg,carousel2.jpg,carousel3.jpg,carousel4.jpg,carousel5.jpg,carousel6.jpg,carousel7.jpg,carousel8.jpg,carousel9.jpg,carousel10.jpg,carousel11.jpg",
			photo4: "show",
			photo1: "show",
			photo2: "show",
			approve_user: "link_belowcost",
			logoPosition: "menu",
			ab: "b",
			logo: "b.png",
			brand_name: "qat-belowcost-shopper.link-nonprod.wawanesalife.com",
		},
	},
};

//updated on Fri Aug 09 2024 02:16:43 GMT+0000 (Coordinated Universal Time)
